// Deprecated. Now handled in module. Mark for removal.

(function($) {
  $(document).ready(function() {
    if($('body.node-type-document').length > 0) {
      $('#local-tasks ul.tabs.primary li').each(function(){
        if ($(this).text().substr(0, 4) == 'View') {
          //$(this).html($(this).html().replace('View', 'Information'));
        }
      })
    }
  });  
})(jQuery);
