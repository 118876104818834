(function($) {
  $(document).ready(function() {
    $('figure').each(function(){
      var figure = $(this);
      var figtitle = figure.find('img').attr('title');
      var figcaption = figure.find("figcaption");
      
      // Add title as figure caption
      
      if (typeof figtitle !== 'undefined' && figtitle !== '') {
        var figtitlehtml = "<h4>" + figtitle + "</h4>";
        
        if (figcaption.length > 0) {
          figcaption.prepend(figtitlehtml);
        } else {
          figure.append("<figcaption>" + figtitlehtml + "</figcaption>");
        }
      }
      
      figure
        .on('mouseenter',function() {
          var fc = $(this).find('figcaption');
          if (fc.length > 0) {
            fc.slideDown(heartbeat);
          }
        })
        .on('mouseleave',function() {
          var fc = $(this).find('figcaption');
          if (fc.length > 0) {
            fc.slideUp(heartbeat);
          }
        });
    });    
  });  
})(jQuery);
