// _default.js must be loaded first
// All other JS files are concatenated afterwards by Gulp

var heartbeat = 300;

// Sets constants for all theme js files.

var themepath = "/sites/all/themes/lsc";
var assetpath = themepath + "/assets";
var imagepath = assetpath + "/img";

var arrow = '<img src="' + imagepath + '/icons/arrow.svg" alt="Navigation Arrow" />';
var arrowBrick = '<img src="' + imagepath + '/icons/arrow-brick.svg" alt="Navigation Arrow" />';
var arrowNeutral = '<img src="' + imagepath + '/icons/arrow-neutral.svg" alt="Navigation Arrow" />';

var openIconSrc = imagepath + '/icons/open.svg';
var closedIconSrc = imagepath + '/icons/closed.svg';
var openIconNeutralSrc = imagepath + '/icons/open-neutral.svg';
var closedIconNeutralSrc = imagepath + '/icons/closed-neutral.svg';

var openIcon = '<img src="' + openIconSrc + '" alt="Open Panel Icon" />';
var closedIcon = '<img src="' + closedIconSrc  + '" alt="Open Panel Icon" />';
var openIconNeutral = '<img src="' + openIconNeutralSrc + '" alt="Open Panel Icon" />';
var closedIconNeutral = '<img src="' + closedIconNeutralSrc + '" alt="Open Panel Icon" />';


(function($) {  
  
  $(document).ready(function() {
    
    // preload arrows
    // ToDo - set up a proper preloader
    
    $(arrowBrick)[0].src = $(arrowBrick).attr('src');
    $(arrowNeutral)[0].src = $(arrowNeutral).attr('src');
    $(openIcon)[0].src = $(openIcon).attr('src');
    $(closedIcon)[0].src = $(closedIcon).attr('src');
    $(openIconNeutral)[0].src = $(openIconNeutral).attr('src');

        
  // People mentioned hack
  // Views is returning empty records for this field. Can’t find the logic in the view. In the meantime – a hack!
  
  var pm = $('.view-id-people_mentioned,.view-id-places_mentioned');
  
  if (pm.length > 0) {
    pm.find('.person,.place').each(function(){
      if ($(this).text() == '') {
        $(this).closest('.views-row').remove();
      }
    });
    
    pm.find('.alias').each(function() {
      if ($(this).text() == '') {
        $(this).closest('.field-content').find('.alias,.alias-label').remove();consl
      }
    })
  }
  
        
  });  
})(jQuery);
