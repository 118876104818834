(function($) {  
  
  $(document).ready(function() {

    // Pager
    
    $('.pager').each(function(){
      
      var pager = $(this);
          
      pager.find('.pager-first,.pager-last').remove(); // Reduce nav elements because all pages of the document are displayed
      pager.find('.pager-next > a, .pager-previous > a').each(function() { // Replace pager text with arrows
        $(this)
          .on('mouseenter',function(){
            $(this).find('img').attr('src',imagepath + '/icons/arrow-brick.svg');
          })
          .on('mouseleave',function(){
            $(this).find('img').attr('src',imagepath + '/icons/arrow.svg');
          })
          .html(arrow);
      });
      
      if (pager.find('.pager-previous').length == 0) {
        pager.prepend("<li class='pager-previous-inactive'>" + arrowNeutral + "</li>");
      }
      
      if (pager.find('.pager-next').length == 0) {
        pager.append("<li class='pager-next-inactive'>" + arrowNeutral + "</li>");
      }
      
      // Add pagecount
      
      var pagecount = pager.find('> *').length -2;
          
      pager.prepend("<li class='pagecount'> Page " + pager.find('.pager-current').text() + " of " + pagecount + "</li>");
          
    });   
  
  });  
})(jQuery);
 
