(function($) {
  $(document).ready(function() {
    $('#block-views-homepage-splash-block .view-content > div').each(function(){
      $(this).find('> *:last-child').prepend("<header>" + $('#site-header').html() + "</header>");
      
      $(this).attr('data-title',$(this).find('.info h2 a').text());
      
      var actions = $(this).find('.actions');
              
      actions.find('a.documents').each(function(){
        var href = $(this).attr('href') + '?edit[field_doc_conspiracy_tid]=' + actions.attr('data-filter');
        $(this).attr('href',href);
      });        
      
      actions.find('a.places').each(function(){
        var href = $(this).attr('href') + '?edit[field_incident_tid]=' + actions.attr('data-filter');
        $(this).attr('href',href);
      });        
    });
        

    // Homepage Splash Navigation  
      
    if ($('body.front').length > 0) {
      
      $('#site-header').hide();
      
      var splashblocks = $('#block-views-homepage-splash-block .view-content > div');
      
      var nav = $('#block-views-homepage-splash-block .task-nav');
      var prev = nav.find('.prev-task');
      var next = nav.find('.next-task');
          
      var slideindex = new Array;
      var slidecount = splashblocks.length;
      var curslide = 0;
  
      // Index all of the slide titles
      
      splashblocks.each(function(i){
        var title = $(this).attr('data-title');
        slideindex.push({
          title: title.length > 0 && title !== '' ? title : 'Next',
          obj: $(this)
        });
      });
              
      next.on('click',function(e){
       e.preventDefault();
       transitionSlide(curslide+1);      
      });
      
      prev.on('click',function(e){
       e.preventDefault();
       transitionSlide(curslide-1);      
      });
      
      setSlideNav();
      
      function transitionSlide(target) {
                    
        if (target < slidecount) {
          slideindex[curslide].obj.animate({opacity: 0},function(){
            $(this).css('display','none');
          })  
          slideindex[target].obj.css('display','flex').animate({opacity: 1});  
        }
        
        curslide = target;
        
        setSlideNav();
              
      }
      
      function setSlideNav() {
        next.hide();
        prev.hide();
        
        if (curslide + 1 < slidecount) {
          next.text(slideindex[curslide+1].title).show(heartbeat);
        }
        
        if (curslide > 0) {
          prev.text(slideindex[curslide-1].title).show(heartbeat);
        }
  
      }
      
    }

  });  
})(jQuery);