(function($) {  
  $(document).ready(function() {

    // Add hover style to display secondary menu
    
    $('#block-system-main-menu .block_content > ul.menu').addClass('primary-menu');
            
    var mainmenu = $('.primary-menu');
    
    mainmenu.each(function() {
      var thismenu = $(this);
      thismenu.find('> li').each(function(){
        $(this).on('mouseenter',function(){
          if ($(this).find('> ul').length > 0) {
            thismenu.addClass('hover');
          }
        });
        
        $(this).on('mouseleave',function(){
          if ($(this).find('> ul').length > 0) {
            thismenu.removeClass('hover');
          }
        })
      });
    });    
  });  
})(jQuery);
