(function($) {
  $(document).ready(function() {
    
   // Document Viewer
    
    var viewer = $('.view-id-document_viewer');
    
    if (viewer.length > 0) {
  
      // Move navigation above viewer and prep
      
      var pagerlabel = viewer.find('> h2.element-invisible');
      var viewerPager = viewer.find('> .item-list');
          
      $('<header />').prependTo(viewer.find('.views-row'))
      
      var header = viewer.find('.views-row > header');
      viewerPager.prependTo(header);
      viewerPager.prependTo(header)
      viewer.find('.views-field-field-docpg-feedback').appendTo(header);
            
      // Accordion Mechanism
      
      viewer.find('.viewer .field-content > div').prepend('<a class="accordion-ui">' + closedIconNeutral + '</a>');    
      
      viewer.find('.accordion-ui').each(function(){
        var aui = $(this);
        var icon = aui.find('>img');
        var panel = aui.closest('div');
        
        panel.addClass('panel-open');
        
        // Add Listeners
        
        aui
          .on('mouseenter',function(){
              if (panel.hasClass('panel-open')) {
                icon.attr('src',closedIconSrc);
              } else {
                icon.attr('src',openIconSrc);
              }
            })
          .on('mouseleave',function(){
              if (panel.hasClass('panel-open')) {
                icon.attr('src',closedIconNeutralSrc);
              } else {
                icon.attr('src',openIconNeutralSrc);
              }
            })
          .on('click',function() {
              var thisPanel = aui.closest('div');
            
              if (thisPanel.hasClass('panel-open')) {
                
                // At least one panel must be open
                
                if (viewer.find('.panel-closed').length < 2) {
                  panel.animate({width: '9%'},heartbeat,'swing');
                  panel.find('> *').not('.accordion-ui').fadeOut(heartbeat);
                  thisPanel.removeClass('panel-open').addClass('panel-closed');
                  icon.animate({opacity: 0},heartbeat/2,function(){
                    icon.attr('src',openIconNeutralSrc);
                    icon.animate({opacity: 1},heartbeat/2);
                  })
                }
                
                if (viewer.find('.panel-closed').length == 2) {
                  viewer.find('.panel-open .accordion-ui img').animate({opacity: 0},heartbeat);
                }
  
              } else {
                panel.animate({width: '100%'},heartbeat,'swing');
                panel.find('> *').not('.accordion-ui').fadeIn(heartbeat);
                thisPanel.removeClass('panel-closed').addClass('panel-open');
                icon.animate({opacity: 0},heartbeat/2,function(){
                  icon.attr('src',closedIconNeutralSrc);
                  icon.animate({opacity: 1},heartbeat/2);
                });   
                
                if (viewer.find('.panel-closed').length < 2) {
                  viewer.find('.panel-open .accordion-ui img').animate({opacity: 1},heartbeat);
                }
              }
            });
              
      });
      
    }
  });  
})(jQuery);
